<template>
	<IndexUser
		:user="user"
	></IndexUser>
</template>

<script>
	import IndexUser from "./IndexUser";
	export default{
		name: 'Main'
		, components: {IndexUser}
		, props: ['user']
		,data: function(){
			return {
				program: {
					name: ''
					, title: ''
					, bottom: true
					, top: true
				}
			}
		}
		,computed: {
			is_merchant: function(){
				let t = false

				if(this.user.agency_type == 'distributor'){
					t = true
				}

				return t
			}
		}
		,methods: {

		}
		,created: function(){
			this.$emit('onLoad', this.program)
		}
	}
	
</script>