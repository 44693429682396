<template>
	<div
		class="flex-column full-height bg-white position-relative"
	>
		<div
			@click="getData"
			class="main-amount flex-row justify-space-between items-center"
		>
			<div class="color-blue size-px-24">잔액</div>
			<div class="items-center flex-row">{{ item.user_balance | makeComma }}원 <button class="ml-10" ><v-icon>mdi-refresh</v-icon></button></div>
		</div>

		<div class="text-center under-line bg-eee">
			<h5>{{ $date.getToday('-') }}</h5>
		</div>

		<div
			class="full-height overflow-y-auto bg-gray-light"
		>
			<ul>
				<li
					v-for="(shop, s_index) in shop_list"
					:key="'shop_' + s_index"
					class=" mb-10 bg-white size-px-16"
				>

					<div
						class="justify-space-between under-line pb-10 pa-10 items-center"
					>
						<h6>상점명</h6>
						<div class="">{{ shop.shop_name }}</div>
					</div>
					<div class="justify-space-between-in-box pa-10">
						<div
						>
							<h6>지갑 주소</h6>
							<div>{{ shop.shop_wallet }}</div>
						</div>
						<div class="">
							<h6>지갑 잔액</h6>
							<div class=" text-right"> {{ shop.shop_balance | makeComma }}원</div>
						</div>
						<div
							class=" "
						>
							<h6>정산 금액</h6>
							<div class=" text-right"> {{ shop.settlement_balance | makeComma }}원</div>
						</div>
						<div
							class=" "
						>
							<h6>지급 예정</h6>
							<div class=" text-right"> {{ shop.deposit_balance | makeComma }}원</div>
						</div>
						<div
							v-if="shop.shop_wallet"
						>
							<h6>출금하기</h6>
							<div>
								<button @click="onWithdrawal(shop)" class="btn-inline btn-primary" :disabled="shop.is_disabled">출금</button>
								<button @click="onSend(shop)" class="btn-inline btn-primary ml-10" :disabled="shop.is_disabled">송금</button>
							</div>
						</div>
						<div
							v-else
						>
							<h6>상점 지갑</h6>
							<div><button v-if="shop.is_btn_wallet" @click="onWallet(shop)" class="btn-inline btn-primary">지갑 생성</button></div>
						</div>
						<div
							v-if="false"
						>
							<h6>출금 계좌</h6>
							<div>
								{{ shop.shop_account }}
								<button
									v-if="!user.is_pin || user.is_pin == '0'"
								>PIN 번호 등록후 이용하세요</button>
								<button
									v-else-if="shop.is_btn_account"
									@click="onAccount(shop)"
									class="btn-inline btn-primary"
								>계좌 등록</button>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<PopupConfirm
			v-if="is_on_wallet"
			@click="postWallet"
			@cancel="offWallet"
		>
			<template
				v-slot:title
			>지갑 생성</template>
			<template
				v-slot:main-txt
			>해당 상점의 지갑을 생성합니다.</template>
		</PopupConfirm>

		<UserAccount
			v-if="is_on_account"
			:user="user"
			:shop_info="item_account"

			@cancel="offAccount"
			@click="postAccount"
		></UserAccount>

		<UserWithdrawal
			v-if="is_on_withdrawal"
			:user="user"
			:shop_info="item_shop"
			:company_fee="company_fee"

			@click="offWithdrawal();getData();"
			@cancel="offWithdrawal"
		></UserWithdrawal>
	</div>
</template>

<script>

import PopupConfirm from "../Layout/PopupConfirm";
import UserAccount from "./UserAccount";
import UserWithdrawal from "./UserWithdrawal";
export default{
	name: 'IndexUser'
	, components: { UserWithdrawal, UserAccount, PopupConfirm}
	, props: ['user']
	,data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, bottom: true
				, top: true
			}
			, item: {
				user_balance: 0
				, settlement_balance: 0
				, deposit_balance: 0
			}
			, items_shop: []
			, item_wallet: {}
			, item_account: {
				bank_code: ''
				, shop_uid: ''
			}
			, is_on_wallet: false
			, is_on_account: false
			, is_on_holder: false
			, is_on_withdrawal: false
			, item_shop: {}
			, company_fee: 0
			, agency_fee: 0
			, is_on_send: false
		}
	}
	,computed: {
		shop_list: function(){
			return this.items_shop.filter( (item) => {
				if(item.virtual_account){
					item.shop_wallet = item.virtual_account
				}else{
					item.is_btn_wallet = true
				}

				if(item.bank_account){
					this.user.bank_list.filter( (bank) => {
						if(item.bank_code == bank.bankId){
							item.bank_name = bank.bankName
						}
					})
					item.shop_account = item.bank_name + ' ' + item.bank_account + ' ' + item.bank_holder
				}else{
					item.is_btn_account = true
				}

				if(item.shop_balance <= 0 || item.is_block){
					item.is_disabled = true
				}
				return item
			})
		}
		, is_to_holder: function(){
			let t = true
			if(this.item_account.bank_code && this.item_account.bank_account && this.item_account.bank_holder){
				t = false
			}
			return t
		}
		, is_to_save: function(){
			let t = true
			if(this.is_on_account && this.item_account.bank_code && this.item_account.bank_account && this.item_account.bank_holder){
				t = false
			}
			return t
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'get'
					, url: 'user/getUserMainInfo'
				})

				if (result.success) {
					this.items_shop = result.data.shop_list
					this.item.user_balance = result.data.user_balance
					this.company_fee = result.data.company_fee
					this.agency_fee = result.data.upper_fee

					this.program.name = this.items_shop[0].shop_name
					this.$bus.$emit('onLoad', this.program)
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				setTimeout( () => {
					this.$bus.$emit('on', false)
				}, 1000)
			}
		}
		, postWallet: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postWallet'
					, data: {
						shop_uid: this.item_wallet.shop_uid
					}
				})

				if (result.success) {
					this.offWallet()
					await this.getData()
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onWallet: function(item){
			this.item_wallet = item
			this.is_on_wallet = true
		}
		, offWallet: function(){
			this.item_wallet = {}
			this.is_on_wallet = false
		}

		, postHolder: async function(){
			this.$bus.$emit('on', true)
			try {
				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postHolder'
					, data: this.item_account
				})

				if (result.success) {
					this.is_on_holder = true
					this.item_account.bank_holder = result.data.realBankOwnerName
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')
			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'check':
						this.postAccount(call)
						break;
				}
			})
		}
		, postCheck: function(){
			if(this.is_on_holder){
				this.onPin()
			}else{
				this.postHolder()
			}
		}
		, onAccount: function(item){
			this.item_account.shop_uid = item.shop_uid
			this.is_on_account = true
		}
		, offAccount: function(){
			this.item_account = {
				bank_code: ''
				, shop_uid: ''
			}
			this.is_on_holder = false
			this.is_on_account = false
		}
		, postAccount: function(){

			this.offAccount()
			this.getData()
		}
		, getBalance: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Axios({
					method: 'get'
					, url: 'user/getBalance'
				})

				if (result.success) {
					this.item.user_balance = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onWithdrawal: function(shop){
			this.$bus.$emit('to', {name: 'UserMultiWithdrawal', params: { shop_uid: shop.shop_uid}})
		}
		, offWithdrawal: function(){
			this.item_shop = {}
			this.is_on_withdrawal = false
			this.is_on_send = false
		}
		, onSend: function(shop){
			this.$bus.$emit('to', {name: 'UserMultiSend', params: { shop_uid: shop.shop_uid}})
		}
	}
	,created: function(){
		this.getData()
	}
}

</script>

<style>
.main-amount {
	font-size: var(--size-px-32);
	font-weight: var(--weight-700);
	text-align: center;
	border-bottom: var(--solid-ddd);
	padding: 30px 10px;
}
</style>

